/* eslint-disable @next/next/no-img-element */
"use client"
import React from 'react';
import 'react-multi-carousel/lib/styles.css';

import Link from 'next/link';
import { BaseVerticalCard } from '../BaseVerticalCard';
import SpaceCarousel from './Components/SpaceCarousel';
import SpaceDescription from './Components/SpaceDescription';
import isEmpty from 'utilities/isEmpty';

export function SpaceCard(props) {
  const { disable, data, setSpaceHoverIdentifier } = props;

  const handleMouseEnter = (e) => {
    if (!isEmpty(setSpaceHoverIdentifier)) {
      setSpaceHoverIdentifier(data.id)
    }
  }

  const handleMouseLeave = (e) => {
    if (!isEmpty(setSpaceHoverIdentifier)) {
      setSpaceHoverIdentifier(null)
    }
  }

  return (
    <Link
      onMouseEnter={(e) => !isEmpty(setSpaceHoverIdentifier) ? handleMouseEnter(e) : null}
      onMouseLeave={(e) => !isEmpty(setSpaceHoverIdentifier) ? handleMouseLeave(e) : null}
      href={data.url}
      className="no-underline group" suppressHydrationWarning={true}>
      <BaseVerticalCard >
        <SpaceCarousel disable={disable} data={data} />
        <div className="card-body prose !leading-[0.2rem] !gap-0 not-prose !pl-2 !pr-2 !pt-1 !pb-2">
          <SpaceDescription data={data} />
        </div>
      </BaseVerticalCard>
    </Link>
  );
}
